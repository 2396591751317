/* eslint-disable no-console, prettier/prettier */
import { isDevelopingApp, isTesting, macroCondition } from '@embroider/macros';

// NOTE: this matches $desktop-width in CSS + 1px
// '+ 1px' because of 110% zoom issue in Chrome & Firefox
const DESKTOP_WIDTH = 1001;
const CONTACT_DESKTOP_WIDTH = 720;

// NOTE: this matches $full-screen-height in CSS
const FULL_SCREEN_HEIGHT = 640;
const CONTACT_FULL_SCREEN_HEIGHT = 700;

const INCOMING_MESSAGE_SCOPE = 'client-portal-iframe-to-origin';
const OUTGOING_MESSAGE_SCOPE = 'client-portal-origin-to-iframe';

const ACCEPTED_PARAMS = [
  'channel',
  'mld',
  'officeId',
  'searchByOffice',
  'referenceId',
  'coveredSessions',
  'clinicianId',
  'selectedSlot',
  'clientType',
  'firstName',
  'lastName',
  'email',
  'birthDate',
  'phoneNumber',
  'otherFirstName',
  'otherLastName',
  'otherEmail',
  'otherBirthDate',
  'otherPhoneNumber',
  'trackingSource',
  'addMessage',
  'reorderedNav',
  'addReasonsForVisit',
  'addReasonsForVisitFlag'
];

const CSS = `
  .spwidget--no-scroll {
    overflow: hidden !important;
    ${
  typeof navigator !== 'undefined' && navigator.userAgent.match(/(iPod|iPhone|iPad)/) ?
    `
      position: fixed;
      width: 100%;
      height: 100%;
    ` :
    ''
}
  }

  .spwidget--overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999998;
    background: rgba(19, 25, 28, .5);
    animation: fadeIn .3s ease-in-out;
  }

  .spwidget--preload {
    display: none;
  }

  .spwidget--scroller {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999999;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  .spwidget--scroller iframe {
    transform: scale(1) translate3d(0, 0, 0);
    animation: fadeInWidget .45s ease-in-out;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes fadeInWidget {
    0% {
      opacity: 0;
      transform: scale(.75) translate3d(0, 100%, 0);
    }

    100% {
      opacity: 1;
      transform: scale(1) translate3d(0, 0, 0);
    }
  }
`;

const ORIGIN = new RegExp(
  /^https?:\/\/(\S*\.)?(\S*\-)?(localhost|simplepractice\.local|client-portal\.local|clientsecure\.me|clientsite\.me)(:[0-9]{2,5})?$/i
);

class Integration {
  constructor(node) {
    this.node = node;
    this._onMessage = this._onMessage.bind(this);
    this._close = this._close.bind(this);
    this._insertCss(CSS);
    this._listen();
    this._callbacks = {};
  }

  init(params) {
    this._contact = params.contact;
    this._scopeUri = params.scopeUri;
    delete params.scopeUri;
    this._fullScreen = params.appearance && params.appearance.fullScreen;
    this._callbacks.onOpen = params.onOpen;
    this._callbacks.onAppointmentRequest = params.onAppointmentRequest;

    if (this._fullScreen) {
      this.node.classList.add('spwidget--preload');
    }

    if (this.iframe) {
      this.iframe.setAttribute('src', this._iframeURL(params));
    } else {
      this._insertIframe(this._iframeURL(params));
    }
  }

  reveal() {
    this.iframe.contentWindow.postMessage({ scope: OUTGOING_MESSAGE_SCOPE, action: 'reveal' }, '*');

    if (this._fullScreen) {
      this._initOverlay();
      this.node.classList.remove('spwidget--preload');
    }

    this._performCallback('onOpen');
  }

  _listen() {
    window.addEventListener('message', this._onMessage, false);
  }

  _onMessage(message) {
    if (!ORIGIN.test(message.origin)) {
      return;
    }

    let { data: messageData } = message;
    let { action, params } = messageData;

    if (messageData.scope !== INCOMING_MESSAGE_SCOPE) {
      return;
    }

    console && console.log(`SPWidget: outgoing message ${JSON.stringify(messageData)}`);

    switch (action) {
      case 'height': {
        if (messageData.height === 0) {
          return;
        }

        let { top, height, marginTop } = this._positionAttributes(messageData.height);
        this.iframe.style.top = `${top}%`;
        this.iframe.style.height = `${height}px`;
        this.iframe.style.marginTop = `${marginTop}px`;

        console && console.log(`SPWidget: setting height to ${height}`);
        break;
      }
      case 'scrollTop':
        this.node.scrollTop = message.data.top;
        break;
      case 'close':
        this._close();
        break;
      case 'appointmentRequested':
        this._performCallback('onAppointmentRequest', params);
        break;
    }
  }

  _initOverlay() {
    document.querySelector('html').classList.add('spwidget--no-scroll');
    document.querySelector('body').classList.add('spwidget--no-scroll');

    this.overlay = this.node.parentNode.insertBefore(document.createElement('div'), this.node);
    this.overlay.classList.add('spwidget--overlay');
    this.overlay.addEventListener('click', this._close);

    this.node.classList.add('spwidget--scroller');
    this.node.addEventListener('click', this._close);
  }

  _close() {
    this.node.classList.remove('spwidget--scroller');
    this.node.classList.add('spwidget--preload');

    this.overlay?.parentNode?.removeChild(this.overlay);

    document.querySelector('html').classList.remove('spwidget--no-scroll');
    document.querySelector('body').classList.remove('spwidget--no-scroll');
  }

  _serializeParams(params) {
    return Object.keys(params)
      .map(k => {
        let key = encodeURIComponent(k);
        let value = params[k];

        if (!value) {
          return;
        }

        if (typeof params[k] === 'object') {
          value = JSON.stringify(params[k]);
        }

        return `${key}=${encodeURIComponent(value)}`;
      })
      .filter(Boolean)
      .join('&');
  }

  _iframeURL(params) {
    if (window.SPWidgetRedirect) {
      let monarchParams = this._serializeParams(this._reduceParams(params));
      monarchParams = monarchParams ? `&${monarchParams}` : '';
      return `${window.SPWidgetRedirect}${monarchParams}`;
    }

    let base = window.SPWidgetBase;
    if (this._scopeUri && this._scopeUri.length > 0) {
      let parts = window.SPWidgetBase.split('://');
      parts.splice(1, 0, `${this._scopeUri}.`);
      parts.splice(1, 0, '://');
      base = parts.join('');
    }

    return `${base}/widget-redirect?${this._serializeParams(params)}`;
  }

  _reduceParams(params) {
    return ACCEPTED_PARAMS.reduce((acc, key) => {
      acc[key] = params[key];
      return acc;
    }, {});
  }

  _isFullHeight() {
    if (this._contact) {
      return window.innerHeight < CONTACT_FULL_SCREEN_HEIGHT && this._fullScreen;
    } else {
      return window.innerWidth < DESKTOP_WIDTH && this._fullScreen;
    }
  }

  _positionAttributes(height) {
    if (this._isFullHeight()) {
      if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
        let zoomLevel = document.documentElement.clientWidth / window.innerWidth;
        height = window.innerHeight * zoomLevel;
      } else {
        height = window.innerHeight;
      }
    } else {
      let fullScreenHeight = this._contact ? CONTACT_FULL_SCREEN_HEIGHT : FULL_SCREEN_HEIGHT;
      height = Math.min(height, fullScreenHeight);
    }

    let top = 50;
    let marginTop = -(height / 2);
    if (!this._fullScreen || this._isFullHeight()) {
      top = 0;
      marginTop = 0;
    }

    return { top, height, marginTop };
  }

  _insertIframe(url) {
    let { top, height, marginTop } =
      this._positionAttributes(this._contact ? CONTACT_FULL_SCREEN_HEIGHT : FULL_SCREEN_HEIGHT);

    this.iframe = document.createElement('iframe');
    this.iframe.setAttribute('class', 'widget-iframe');
    this.iframe.setAttribute('src', url);
    this.iframe.setAttribute('frameBorder', '0');
    this.iframe.setAttribute('allowtransparency', 'true');
    this.iframe.setAttribute('allow', 'geolocation');
    this.iframe.setAttribute(
      'style',
      [
        'display: block',
        'position: relative',
        `top: ${top}%`,
        'width: 100%',
        `max-width: ${this._contact ? CONTACT_DESKTOP_WIDTH : DESKTOP_WIDTH}px`,
        `height: ${height}px`,
        'margin: 0 auto',
        `margin-top: ${marginTop}px`,
      ].join('; ')
    );
    this.iframe.setAttribute('title', this._contact ? 'Send message': 'Request an Appointment');

    this.node.appendChild(this.iframe);
  }

  _insertCss(stylesheet) {
    if (document.querySelector('#spwidget-css')) {
      return;
    }

    let style = document.createElement('style');
    style.id = 'spwidget-css';

    if (style.styleSheet) {
      style.styleSheet.cssText = stylesheet;
    } else {
      style.appendChild(document.createTextNode(stylesheet));
    }

    document.head.appendChild(style);
  }

  _performCallback(name, params = {}) {
    let callback = this._callbacks[name];

    if (typeof callback === 'function') {
      callback(params);
    }
  }
}

window.SPWidget = Integration;
window.SPWidgetInstances = {};

if (window.railsEnv === 'test') {
  // no-op
} else if (macroCondition(isTesting())) {
  window.SPWidgetBase = 'http://localhost:7357';
} else if (macroCondition(isDevelopingApp())) {
  window.SPWidgetBase = 'http://simplepractice.local';
  window.__CLASSIC_HAS_CONSTRUCTOR__ = new WeakMap();
  window.__CLASSIC_OWN_CLASSES__ = new WeakMap();
} else {
  if (SP_WIDGET_BASE_OVERRIDABLE_PLACEHOLDER === 'true') { // eslint-disable-line no-undef
    if (!window.SPWidgetBase) {
      window.SPWidgetBase = 'SP_WIDGET_BASE_PLACEHOLDER';
    }
  } else {
    window.SPWidgetBase = 'SP_WIDGET_BASE_PLACEHOLDER';
  }
}

typeof document !== 'undefined' && document.querySelectorAll('[data-spwidget-autobind]').forEach(function (clickable) {
  let scopeId = clickable.getAttribute('data-spwidget-scope-id');
  let scopeUri = clickable.getAttribute('data-spwidget-scope-uri');
  let scopeGlobal = clickable.hasAttribute('data-spwidget-scope-global');
  let applicationId = clickable.getAttribute('data-spwidget-application-id');
  let channel = clickable.getAttribute('data-spwidget-channel');
  let searchByOffice = clickable.getAttribute('data-spwidget-search-by-office');
  let officeId = clickable.getAttribute('data-spwidget-office-id');
  let referenceId = clickable.getAttribute('data-spwidget-reference-id');
  let coveredSessions = clickable.getAttribute('data-spwidget-covered-sessions');
  let monarchLeadId = clickable.getAttribute('data-spwidget-mld');
  let clinicianId = clickable.getAttribute('data-spwidget-clinician-id');
  let cptCodeId = clickable.getAttribute('data-spwidget-cpt-code-id');
  let selectedSlot = clickable.getAttribute('data-spwidget-selected-slot');
  let clientType = clickable.getAttribute('data-spwidget-client-type');
  let firstName = clickable.getAttribute('data-spwidget-first-name');
  let lastName = clickable.getAttribute('data-spwidget-last-name');
  let email = clickable.getAttribute('data-spwidget-email');
  let phoneNumber = clickable.getAttribute('data-spwidget-phone-number');
  let birthDate = clickable.getAttribute('data-spwidget-birth-date');
  let otherFirstName = clickable.getAttribute('data-spwidget-other-first-name');
  let otherLastName = clickable.getAttribute('data-spwidget-other-last-name');
  let otherEmail = clickable.getAttribute('data-spwidget-other-email');
  let otherPhoneNumber = clickable.getAttribute('data-spwidget-other-phone-number');
  let otherBirthDate = clickable.getAttribute('data-spwidget-other-birth-date');
  let contact = clickable.hasAttribute('data-spwidget-contact');

  let type = contact ? 'contact' : 'appointment';
  let key = `${scopeId}-${type}`;

  if (scopeId && applicationId) {
    if (!window.SPWidgetInstances[key]) {
      let node = document.body.appendChild(document.createElement('div'));
      window.SPWidgetInstances[key] = new window.SPWidget(node);
      window.SPWidgetInstances[key].init({
        scopeId,
        scopeUri,
        scopeGlobal,
        applicationId,
        channel,
        searchByOffice,
        officeId,
        referenceId,
        coveredSessions,
        mld: monarchLeadId,
        appearance: {
          fullScreen: true
        },
        clinicianId,
        cptCodeId,
        selectedSlot,
        clientType,
        firstName,
        lastName,
        email,
        birthDate,
        phoneNumber,
        otherFirstName,
        otherLastName,
        otherEmail,
        otherBirthDate,
        otherPhoneNumber,
        contact,
      });
    }

    clickable.onclick = function (e) {
      e.preventDefault();
      window.SPWidgetInstances[key].reveal();
    }
  }
});
